<template>
  <div>
    <div class="row g-2 ps-0">
      <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-header">
            <div class="row flex-between-center border-bottom">
              <div class="col-4 col-sm-auto align-items-center pe-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                  <i class="fa fa-shopping-cart text-warning"></i>Generate Purchase Order
                </h5>
                <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
                  Instantly create PO
                </p>
              </div>

              <div class="col-8 col-sm-auto ms-auto text-end ps-0 d-flex mb-1">
                <button class="btn btn-sm me-2" :class="list_view ? 'btn-falcon-warning' : 'btn-warning'"
                  @click="toggleView">
                  <i class="fa fa-table"></i>
                </button>

                <div class="input-group">
                  <input class="form-control shadow-none search ms-auto" type="search" placeholder="Search..."
                    aria-label="search" v-model="search_term" v-on:keyup="searchItem" />

                  <div class="input-group-text bg-transparent">
                    <span class="fa fa-search fs--1 text-600"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body pt-0">
            <div class="row">
              <div class="col-12">
                <div class="" id="ordersTable"
                  data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'>
                  <div class="card mb-3">
                    <div class="card-body">
                      <div class="row align-items-center text-center mb-3">
                        <div class="col-sm-6 text-sm-start">
                          <img :src="user.company.logo" alt="invoice" width="150" />
                        </div>

                        <div class="col text-sm-end mt-3 mt-sm-0">
                          <h4 class="mb-3 fw-bold fs-bold">PURCHASE ORDER</h4>
                          <h5></h5>
                          <p class="fs--1 mb-0 fw-bold">
                            {{ user.company.name }},
                            {{ user.company.address_line1 }}
                            <br />{{ user.company.address_line2 }} <br />{{ user.company.address_line3 }}
                          </p>
                        </div>

                        <div class="col-12">
                          <hr />
                        </div>
                      </div>

                      <div class="row align-items-center">
                        <div class="col">
                          <h5>Supplier</h5>

                          <Select2 class="mb-4" placeholder="Select Supplier..." v-model="formData.supplier_id"
                            :options="suppliers" @select="mySelectEvent($event)" style="width: 50%" />
                        </div>

                        <div class="col-sm-auto ms-auto">
                          <div class="table-responsive">
                            <table class="table table-sm table-borderless fs--1">
                              <tbody>
                                <tr>
                                  <th class="text-sm-end">POS Date:</th>

                                  <td>2018-09-25</td>
                                </tr>

                                <tr class="alert alert-success fw-bold">
                                  <th class="text-sm-end">Total Amount:</th>

                                  <td class="ps-auto">
                                    {{ formatToCurrency(totalValue) }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div class="table-responsive scrollbar mt-4 fs--1">
                        <div class="card border-right scrollbar list" style="max-height: 100%">
                          <div class="card-header bg-light d-flex">
                            <h6 class="mb-md-0">
                              PO Products ({{ cart.length || "No" }} Items)
                            </h6>
                          </div>

                          <div class="table-responsive scrollbar">
                            <table class="table table-bordered table-striped">
                              <thead>
                                <tr class="bg-light fw-bold text-primary">
                                  <th class="fs--1" scope="col" style="width: 50%">
                                    Name
                                  </th>

                                  <th class="text-center fs--1" style="width: 15%">
                                    Qty
                                  </th>

                                  <th class="text-end fs--1" style="width: 20%">
                                    Total
                                  </th>

                                  <th class="text-end" style="width: 15%">
                                    Action
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr v-for="(item, i) in cartItems" :key="i">
                                  <td class="fs--1">
                                    {{ truncateText(item.name, 50) }}
                                  </td>

                                  <td class="fs--1">
                                    <div>
                                      <div class="input-group input-group-sm flex-nowrap" data-quantity="data-quantity">
                                        <button class="btn btn-sm btn-outline-secondary border-300 px-2 shadow-none"
                                          data-type="minus" @click="
                                            editItem(item, item.quantity - 1)
                                            ">
                                          -
                                        </button>

                                        <input class="form-control text-center px-2 input-spin-none fs--1" type="number"
                                          min="1" v-model="item.quantity" @change="editItemQty(item, $event)"
                                          aria-label="Amount (to the nearest dollar)" style="width: 50px" />

                                        <button class="btn btn-sm btn-outline-secondary border-300 px-2 shadow-none"
                                          data-type="plus" @click="
                                            editItem(item, item.quantity + 1)
                                            ">
                                          +
                                        </button>
                                      </div>
                                    </div>
                                  </td>

                                  <td class="text-end fs--1">
                                    <!-- Start of price  -->

                                    <span v-if="!allow_price_edits" class="fw-bold">{{
                                      formatToCurrency(
                                        (retail_price
                                          ? item.price
                                          : item.ws_price) * item.quantity
                                      )
                                    }}</span>

                                    <span v-if="allow_price_edits">
                                      <input v-if="retail_price" class="form-control" :value="item.price * item.quantity"
                                        @change="editItemPrice($event, item)" />

                                      <input v-else class="form-control" :value="item.ws_price * item.quantity"
                                        @change="editItemPrice($event, item)" />
                                    </span>

                                    <!-- End of price  -->

                                    <br />

                                    <span class="fs--2 d-block text-primary">{{
                                      item.vat
                                      ? `VAT:` +
                                      formatToCurrency(
                                        getVAT(
                                          (retail_price
                                            ? item.price
                                            : item.ws_price) * item.quantity
                                        )
                                      )
                                      : ""
                                    }}</span>
                                  </td>

                                  <td class="text-end">
                                    <button class="btn btn-link p-0 ms-2 text-danger fs--1" type="button"
                                      data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"
                                      @click="deleteItem(item)">
                                      Delete

                                      <span class="text-500 fas fa-trash-alt text-danger"></span>
                                    </button>
                                  </td>
                                </tr>

                                <tr class="bg-light fs--1">
                                  <td class="text-900 fw-bold" colspan="3">
                                    TOTAL (Ksh)
                                  </td>

                                  <td class="fs--3 fw-bold text-end">
                                    {{ formatToCurrency(totalValue) }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="row">
                            <div class="col d-flex">
                              <button @click="orderTicket" class="btn btn-falcon-success ms-auto me-2 my-1">
                                <i class="fa fa-save me-2"></i>Submit
                              </button>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End : Invoice  -->
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="authentication-modal" tabindex="-1" role="dialog"
      aria-labelledby="authentication-modal-label" aria-hidden="true">
      <div class="modal-dialog mt-6" role="document">
        <div class="modal-content border-0">
          <div class="modal-header px-5 position-relative modal-shape-header bg-shape">
            <div class="position-relative z-index-1 light">
              <h4 class="mb-0 text-white" id="authentication-modal-label">
                Register
              </h4>

              <p class="fs--1 mb-0 text-white">
                Please create your free Falcon account
              </p>
            </div>

            <button class="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>

          <div class="modal-body py-4 px-5">
            <form>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-name">Name</label><input class="form-control" type="text"
                  autocomplete="on" id="modal-auth-name" />
              </div>

              <div class="mb-3">
                <label class="form-label" for="modal-auth-email">Email address</label><input class="form-control"
                  type="email" autocomplete="on" id="modal-auth-email" />
              </div>

              <div class="row gx-2">
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-password">Password</label><input class="form-control"
                    type="password" autocomplete="on" id="modal-auth-password" />
                </div>

                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-confirm-password">Confirm Password</label><input
                    class="form-control" type="password" autocomplete="on" id="modal-auth-confirm-password" />
                </div>
              </div>

              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="modal-auth-register-checkbox" /><label
                  class="form-label" for="modal-auth-register-checkbox">I accept the <a href="index.html#!">terms </a>and

                  <a href="index.html#!">privacy policy</a></label>
              </div>

              <div class="mb-3">
                <button class="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">
                  Register
                </button>
              </div>
            </form>

            <div class="position-relative mt-5">
              <hr />

              <div class="divider-content-center">or register with</div>
            </div>

            <div class="row g-2 mt-2">
              <div class="col-sm-6">
                <a class="btn btn-outline-google-plus btn-sm d-block w-100" href="index.html#"><span
                    class="fab fa-google-plus-g me-2" data-fa-transform="grow-8"></span>

                  google</a>
              </div>

              <div class="col-sm-6">
                <a class="btn btn-outline-facebook btn-sm d-block w-100" href="index.html#"><span
                    class="fab fa-facebook-square me-2" data-fa-transform="grow-8"></span>

                  facebook</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-background-color: #edf2f9;
  --easy-table-body-row-height: 60px;
  --easy-table-body-row-font-size: 12px;
}

.page-link {
  padding-left: 18px !important;
  padding-right: 18px !important;
}
</style>

<script>
import CountUp from "vue-countup-v3";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Pagination from "v-pagination-3";
import CartService from "../services/cart.service";
import AuthService from "../services/auth.service";
import TablesService from "../services/tables.service";
import TicketService from "../services/ticket.service";
import ProductService from "../services/product.service";
import MenuItemService from "../services/menu_item.service";
import MenuGroupService from "../services/menu_group.service";
import MenuCategoryService from "../services/menu_category.service";
import SupplierService from "../services/supplier.service";
import OrderTypeService from "../services/order_types.service";
import useVuelidate from "@vuelidate/core";
import OrderService from "../services/orders.service";
import { required } from "@vuelidate/validators";
import NotificationService from "../services/notification.service";

export default {
  mixins: [mixins],
  name: "NewPurchasesView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        name: { required },
        price: { required },
      },
    };
  },
  components: {
    CountUp,
    Pagination,
  },
  data() {
    return {
      search_term: "",
      list_view: true,
      retail_price: true,
      per_page: 25,
      current_page: 0,
      records: 0,
      checkout: false,
      daily_analysis: [],
      weekly_analysis: [],
      formData: {
        table_id: 1,
        supplier_id: "",
        order_type_id: "",
        order_type_name: "",
        cart: {},
        total_amount: 0,
      },
      searchKey: "",
      barcode: "",
      loading_qr: false,
      dialog: false,
      items: {},
      tables: [],
      order_types: [],
      dialogQty: false,
      itemDialog: false,
      productCategories: [],
      suppliers: [],
      groups: [],
      selectedProductCategory: "",
      cart: {},
      table_selection: localStorage.getItem("table") == null ? true : false,
      category_selection: false,
      order_type_selection: true,
      order_type:
        localStorage.getItem("order_type") == null
          ? {}
          : JSON.parse(localStorage.getItem("order_type")),
      selectedCategory: 0,
      selected_category: "",
      selected_category_data: {},
      selected_group: "",
      selected_table: "",
      selected_customer: "",
    };
  },
  async created() {
    let status = await AuthService.checkAuth();
    if (status == 401) {
      localStorage.removeItem("user");
      alert("Session expired");
      this.$router.push("/login");
    }
    this.getAllOrderTypes();
    this.getAllCategories();
    this.getAllGroups();
    // this.getAllTables();
    this.getSuppliers();
  },
  computed: {
    totalValue() {
      let itemArray = Object.values(this.cart);
      let totalValue = 0;
      itemArray.forEach((element) => {
        totalValue +=
          parseFloat(element.quantity) *
          parseFloat(this.retail_price ? element.price : element.ws_price);
      });
      return parseFloat(totalValue);
    },
    totalVat() {
      let itemArray = Object.values(this.cart);
      let totalValue = 0;
      itemArray.forEach((element) => {
        if (element.vat) {
          totalValue +=
            parseFloat(element.quantity) *
            parseFloat(
              this.retail_price
                ? this.getVAT(element.price)
                : this.getVAT(element.ws_price)
            );
        }
      });
      return totalValue;
    },
    cartLength() {
      let data = this.cart;
      if (data.length) {
        return data.length;
      } else {
        return "empty";
      }
    },
    cartItems() {
      let cartData = Object.values(this.cart);
      return cartData;
    },
    table() {
      return localStorage.getItem("table") == null ? {} : this.selected_table;
    },
    category() {
      return this.selected_category_data;
    },
    company() {
      return JSON.parse(localStorage.getItem("user")).company;
    },
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
    allow_price_edits() {
      let user = JSON.parse(localStorage.getItem("user"));
      return user ? user.company.allow_price_edits : false;
    },
  },
  methods: {
    editItemQty(item, evt) {
      this.editItem(item, evt.target.value);
      console.log(evt.target.value);
    },
    getNotifications() {
      return NotificationService.latest().then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_NOTIFICATIONS", response.data.data);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    mySelectEvent({ id, text }) {
      console.log({ id, text });
      this.formData.supplier_id = id;
      this.formData.customer_name = text;
    },
    editItemPrice(e, item) {
      console.log("price", e.target.value);
      console.log("item", item);
      let newPrice = e.target.value;
      this.updateSingleItemPrice(item, newPrice);
    },
    updateSingleItemPrice(item, newPrice) {
      console.log("newPrice", newPrice);
      let myCart = this.cart ? Object.values(this.cart) : [];
      let existingElement = myCart.find((element) => element.id == item.id);
      item.sub_total = newPrice;

      existingElement.price = newPrice / item.quantity;
      existingElement.sub_total = newPrice;
      console.log("myCart", myCart);
      this.cart = myCart;
      this.$store.dispatch("pos/updateCart", this.cart);
    },

    toggleRetail() {
      this.retail_price = !this.retail_price;
    },
    toggleView() {
      this.list_view = !this.list_view;
    },
    selectGroup(e) {
      this.selected_group = e;
      let formData = {
        menu_group_id: e,
      };
      this.menuList(formData, 1);
    },
    searchItem() {
      this.page = 1;
      this.per_page = 25;
      this.current_page = 0;
      let formData = {
        search_term: this.search_term,
      };
      this.$store.commit("SET_LOADING", true);
      return MenuItemService.menuSearch(formData, this.current_page).then(
        (response) => {
          if (response.data.code == 200) {
            this.items = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    deleteItem(item) {
      this.cart.splice(this.cart.indexOf(item), 1);
    },
    editItem(item, quantity) {
      if (quantity == 0) {
        this.cart.splice(this.cart.indexOf(item), 1);
      }
      let myCart = this.cart ? Object.values(this.cart) : [];
      if (myCart.includes(item)) {
        let existingElement = myCart.find((element) => element.id == item.id);
        existingElement.quantity = quantity;
        existingElement.sub_total = quantity * item.price;
      }
      this.cart = myCart;
      toast.success("1 " + item.name + " updated in cart");
    },
    truncateText(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    toggleCheckout(val) {
      this.checkout = val;
    },
    formatToCurrency: function (amount) {
      return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    sortItems(list) {
      return list.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    getTimegreetings() {
      const time = new Date().getHours();
      let greetings;
      if (time < 12) greetings = "Good Morning";
      else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
      else greetings = "Good Evening";
      return greetings;
    },
    tenderedInput() {
      this.formData.balance_amount =
        parseFloat(this.formData.tender_amount) - parseFloat(this.totalValue);
    },
    payMpesa() {
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      // Custome for cash
      this.formData.settled = 1;
      this.formData.payment_method = "MPESA";
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.id;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.id;
      this.formData.order_type_name = this.order_type.name;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Sale placed!");
            this.getNotifications();
            this.cart = {};
            // Print ticket
            let company = JSON.parse(localStorage.getItem("user")).company;
            if (company.type == "supermarket") {
              // Print receipt
              this.formData.ticket_items = JSON.stringify(
                this.formData.cart.map((el) => {
                  return {
                    name: el.name,
                    price: this.formatToCurrency(
                      this.retail_price ? el.price : el.ws_price
                    ),
                    quantity: el.quantity,
                    subtotal: this.formatToCurrency(
                      (this.retail_price ? el.price : el.ws_price) * el.quantity
                    ),
                    vat: el.vat
                      ? this.formatToCurrency(
                        this.getVAT(
                          (this.retail_price ? el.price : el.ws_price) *
                          el.quantity
                        )
                      )
                      : 0,
                  };
                })
              );
              this.$store.commit("SET_LOADING", true);
              this.editedItem = response.data.data;
              this.formData.os = "windows";
              this.formData.restaurant_name = this.user.company.name;
              this.formData.date = this.formatDate(this.editedItem.create_date);
              this.formData.reference = this.createReference(
                this.editedItem.create_date,
                this.editedItem.id,
                "BL"
              );
              this.formData.created_by = this.user.first_name;
              this.formData.address_line_1 = this.user.company.address_line1;
              this.formData.address_line_2 = this.user.company.address_line2;
              this.formData.address_line_3 = this.user.company.address_line3;
              this.formData.footer = this.user.company.ticket_footer;
              this.formData.sub_total = this.formatToCurrency(
                this.editedItem.total_price -
                this.getVAT(this.editedItem.total_price)
              );
              this.formData.tax = this.editedItem.total_tax;
              this.formData.total_amount = this.formatToCurrency(
                this.editedItem.total_price
              );
              this.formData.printer_ip = "127.0.0.1";
              this.formData.vat = this.formatToCurrency(
                this.getVAT(this.editedItem.total_price)
              );
              OrderService.printReceiptSuper(this.formData).then(
                (response) => {
                  toast.success("Receipt printed!", {
                    autoClose: 2000,
                  });
                  console.log(response);
                  this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                  this.$store.commit("SET_LOADING", false);
                  toast.error(
                    error.response.data.message ||
                    (error.response && error.response.data) ||
                    error.mesage ||
                    error.toString(),
                    { autoClose: 3000 }
                  );
                  console.log(error);
                }
              );
            }
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    payCash() {
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      // Custome for cash
      this.formData.settled = 1;
      this.formData.payment_method = "CASH";
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.id;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.id;
      this.formData.order_type_name = this.order_type.name;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Sale placed!");
            this.getNotifications();
            this.cart = {};
            // Print ticket
            let company = JSON.parse(localStorage.getItem("user")).company;
            if (company.type == "supermarket") {
              // Print receipt
              this.formData.ticket_items = JSON.stringify(
                this.formData.cart.map((el) => {
                  return {
                    name: el.name,
                    price: this.formatToCurrency(
                      this.retail_price ? el.price : el.ws_price
                    ),
                    quantity: el.quantity,
                    subtotal: this.formatToCurrency(
                      (this.retail_price ? el.price : el.ws_price) * el.quantity
                    ),
                    vat: el.vat
                      ? this.formatToCurrency(
                        this.getVAT(
                          (this.retail_price ? el.price : el.ws_price) *
                          el.quantity
                        )
                      )
                      : 0,
                  };
                })
              );
              this.$store.commit("SET_LOADING", true);
              this.editedItem = response.data.data;
              this.formData.os = "windows";
              this.formData.restaurant_name = this.user.company.name;
              this.formData.date = this.formatDate(this.editedItem.create_date);
              this.formData.reference = this.createReference(
                this.editedItem.create_date,
                this.editedItem.id,
                "BL"
              );
              this.formData.created_by = this.user.first_name;
              this.formData.address_line_1 = this.user.company.address_line1;
              this.formData.address_line_2 = this.user.company.address_line2;
              this.formData.address_line_3 = this.user.company.address_line3;
              this.formData.footer = this.user.company.ticket_footer;
              this.formData.sub_total = this.formatToCurrency(
                this.editedItem.total_price -
                this.getVAT(this.editedItem.total_price)
              );
              this.formData.tax = this.editedItem.total_tax;
              this.formData.total_amount = this.formatToCurrency(
                this.editedItem.total_price
              );
              this.formData.printer_ip = "127.0.0.1";
              this.formData.vat = this.formatToCurrency(
                this.getVAT(this.editedItem.total_price)
              );
              OrderService.printReceiptSuper(this.formData).then(
                (response) => {
                  toast.success("Receipt printed!", {
                    autoClose: 2000,
                  });
                  console.log(response);
                  this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                  this.$store.commit("SET_LOADING", false);
                  toast.error(
                    error.response.data.message ||
                    (error.response && error.response.data) ||
                    error.mesage ||
                    error.toString(),
                    { autoClose: 3000 }
                  );
                  console.log(error);
                }
              );
            }
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    payCard() {
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      // Custome for cash
      this.formData.settled = 1;
      this.formData.payment_method = "CARD";
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.id;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.id;
      this.formData.order_type_name = this.order_type.name;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Sale placed!");
            this.getNotifications();
            this.cart = {};
            // Print ticket
            let company = JSON.parse(localStorage.getItem("user")).company;
            if (company.type == "supermarket") {
              // Print receipt
              this.formData.ticket_items = JSON.stringify(
                this.formData.cart.map((el) => {
                  return {
                    name: el.name,
                    price: this.formatToCurrency(
                      this.retail_price ? el.price : el.ws_price
                    ),
                    quantity: el.quantity,
                    subtotal: this.formatToCurrency(
                      (this.retail_price ? el.price : el.ws_price) * el.quantity
                    ),
                    vat: el.vat
                      ? this.formatToCurrency(
                        this.getVAT(
                          (this.retail_price ? el.price : el.ws_price) *
                          el.quantity
                        )
                      )
                      : 0,
                  };
                })
              );
              this.$store.commit("SET_LOADING", true);
              this.editedItem = response.data.data;
              this.formData.os = "windows";
              this.formData.restaurant_name = this.user.company.name;
              this.formData.date = this.formatDate(this.editedItem.create_date);
              this.formData.reference = this.createReference(
                this.editedItem.create_date,
                this.editedItem.id,
                "BL"
              );
              this.formData.created_by = this.user.first_name;
              this.formData.address_line_1 = this.user.company.address_line1;
              this.formData.address_line_2 = this.user.company.address_line2;
              this.formData.address_line_3 = this.user.company.address_line3;
              this.formData.footer = this.user.company.ticket_footer;
              this.formData.sub_total = this.formatToCurrency(
                this.editedItem.total_price -
                this.getVAT(this.editedItem.total_price)
              );
              this.formData.tax = this.editedItem.total_tax;
              this.formData.total_amount = this.formatToCurrency(
                this.editedItem.total_price
              );
              this.formData.printer_ip = "127.0.0.1";
              this.formData.vat = this.formatToCurrency(
                this.getVAT(this.editedItem.total_price)
              );
              OrderService.printReceiptSuper(this.formData).then(
                (response) => {
                  toast.success("Receipt printed!", {
                    autoClose: 2000,
                  });
                  console.log(response);
                  this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                  this.$store.commit("SET_LOADING", false);
                  toast.error(
                    error.response.data.message ||
                    (error.response && error.response.data) ||
                    error.mesage ||
                    error.toString(),
                    { autoClose: 3000 }
                  );
                  console.log(error);
                }
              );
            }
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    completeCheckout() {
      this.preview_cash_modal = false;
      this.preview_dc_modal = false;
      this.digital_change = false;
      this.change_sent = false;
      this.qrData = {};
      this.clearCart();
      this.$notify({
        group: "foo",
        type: "success",
        title: "Checkout complete",
        text: "order places and paid successfuly!",
      });
    },
    async categorySelected(cat) {
      this.selected_category_data = cat;
      this.selected_category = cat.id;
      this.getMenuGroupsByCategory(cat.id);
    },
    getMenuGroupsByCategory(selected_category) {
      let formData = {
        menu_category: selected_category,
      };
      return ProductService.getMenuGroupsByCategory(formData).then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
            if (response.data.data.length > 0) {
              let formData = {
                menu_group_id: this.groups.at(0).id,
              };
              this.menuList(formData, 1);
            }
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },

    orderTicket() {
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      this.formData.cart = this.cart;
      this.formData.retail_price = this.retail_price;
      this.formData.table_id = this.selected_table.id;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.id;
      this.formData.order_type_name = this.order_type.name;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Order placed!");
            this.getNotifications();
            this.cart = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    clearCart() {
      this.cart = {};
      toast.info("Cart cleared!");
    },
    goToMyOrders() {
      this.$router.push("/my-orders");
    },
    goToAllOrders() {
      this.$router.push("/pos-open-order");
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    menuList(formData, page) {
      this.$store.commit("SET_LOADING", true);
      return MenuItemService.menuList(formData, page).then(
        (response) => {
          if (response.data.code == 200) {
            this.items = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getItemsByOrder(item) {
      this.$store.commit("SET_LOADING", true);
      return CartService.byOrder(item.id).then(
        (response) => {
          if (response.data.code == 200) {
            this.items = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    myCallback(e) {
      let formData = {};
      if (this.selected_group) {
        formData.menu_group_id = this.selected_group;
      }
      if (this.order_type.id) {
        formData.order_type_id = this.order_type.id;
      }
      if (this.search_term) {
        this.searchItem();
      } else {
        this.menuList(formData, e);
      }
    },
    getAll() {
      return CartService.getAll().then(
        (response) => {
          if (response.data.code == 200) {
            this.items = response.data.data.data;
            this.current_page = response.data.data.current_page;
            this.$store.commit("SET_LOADING", false);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllTables() {
      this.$store.commit("SET_LOADING", true);
      return TablesService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.tables = response.data.data;
            if (this.tables.length > 0) this.selected_table = this.tables.at(0);
            this.$store.commit("SET_LOADING", falses);
          } else {
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getAllGroups() {
      this.$store.commit("SET_LOADING", true);
      return MenuGroupService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getAllCategories() {
      this.$store.commit("SET_LOADING", true);
      return MenuCategoryService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.productCategories = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getAllOrderTypes() {
      return OrderTypeService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.order_types = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuGroupsByOrder() {
      let formData = {
        order_type: this.order_type.id,
      };
      return ProductService.getMenuGroupsByOrder(formData).then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
            this.selected_group = response.data.data.at(0).id;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getSuppliers() {
      return SupplierService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.suppliers = response.data.data.map((el) => {
              return {
                id: el.id,
                text: el.full_name,
              };
            });
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllProducts() {
      return ProductService.getAll().then(
        (response) => {
          if (response.data.status == "success") {
            this.items = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuCategoryByOrder(order_type) {
      if (this.order_types.length > 0) {
        this.formData.order_type = order_type;
        return ProductService.getMenuCategoryByOrder(this.formData).then(
          (response) => {
            if (response.data.code == 200) {
              this.productCategories = response.data.data;
            } else {
              console.log(response.data.error);
            }
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      } else {
        this.productCategories = [];
      }
    },
    addTocart(item) {
      let myCart = this.cart ? Object.values(this.cart) : [];
      let current_price = this.retail_price ? item.price : item.ws_price;
      if (myCart.includes(item)) {
        let existingElement = myCart.find((element) => element.id == item.id);
        item.sub_total = item.quantity * current_price;
        existingElement.quantity = existingElement.quantity + 1;
        existingElement.sub_total =
          existingElement.sub_total + item.quantity * current_price;
      } else {
        item.quantity = 1;
        item.sub_total = item.quantity * current_price;
        myCart.push(item);
      }
      this.cart = myCart;
      this.$store.dispatch("pos/updateCart", this.cart);
    },
    selectTable(item) {
      this.table_selection = false;
      localStorage.setItem("table", JSON.stringify(item));
      this.$notify({
        group: "foo",
        type: "info",
        title: "Selection",
        text: "Table selected!",
      });
    },
    selectOrderType(item) {
      this.order_type = item;
      this.table_selection = true;
      this.order_type_selection = false;
      localStorage.setItem("order_type", JSON.stringify(item));
      let formData = {
        order_type_id: item.id,
      };
      this.menuList(formData, 1);
      this.getMenuCategoryByOrder(item.id);
    },
  },
};
</script>
