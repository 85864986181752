<template>
  <div>
    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    <main class="main" id="top">
      <div class="container" data-layout="container">
        <div class="row flex-center min-vh-100 py-6">
          <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
            <div class="card">
              <div class="card-header">
                <a class="d-flex flex-center" href="https://bulla.co.ke">
                  <img class="me-2" src="assets/img/logo.png" alt="logo" height="32" />
                </a>
              </div>
              <div class="position-relative">
                <hr />
                <div class="divider-content-center">
                  <h5>Request Demo</h5>
                </div>
              </div>
              <div class="card-body">
                <div class="input-group mb-3">
                  <span
                    class="input-group-text"
                    id="basic-addon1"
                    :class="name_focused ? 'text-warning' : 'text-secondary'"
                  >
                    <i class="fas fa-user-alt"></i>
                  </span>
                  <input
                    v-model="formData.name"
                    class="form-control input-lg"
                    type="text"
                    placeholder="Full Name"
                    aria-label="Full Name"
                    @focus="nameFocus(true)"
                    @blur="nameFocus(false)"
                    aria-describedby="basic-addon1"
                    v-on:keydown.enter="handleRegister"
                    :class="
                      v$.formData.name.$dirty && v$.formData.name.$invalid
                        ? `is-invalid`
                        : v$.formData.name.$dirty
                        ? `is-valid`
                        : ``
                    "
                  />
                  <div
                    v-for="error of v$.formData.name.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    Oops! The name is invalid.
                  </div>
                </div>

                <div class="input-group mb-3">
                  <span
                    class="input-group-text"
                    id="basic-addon1"
                    :class="phone_focused ? 'text-warning' : 'text-secondary'"
                  >
                    <i class="fas fa-phone"></i>
                  </span>
                  <input
                    v-model="formData.phone"
                    class="form-control input-lg"
                    type="text"
                    placeholder="e.g 0712345678"
                    aria-label="Phone Number"
                    @focus="phoneFocus(true)"
                    @blur="phoneFocus(false)"
                    aria-describedby="basic-addon1"
                    v-on:keydown.enter="handleRegister"
                    :class="
                      v$.formData.phone.$dirty && v$.formData.phone.$invalid
                        ? `is-invalid`
                        : v$.formData.phone.$dirty
                        ? `is-valid`
                        : ``
                    "
                  />
                  <div
                    v-for="error of v$.formData.phone.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    Oops! The phone is invalid.
                  </div>
                </div>

                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1">
                    <div class="btn-group">
                      <div class="dropdown font-sans-serif d-inline-block">
                        <a
                          :class="username_focused ? ' text-warning' : 'text-secondary'"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          ><i class="fa fa-envelope mt-1"></i
                        ></a>
                      </div>
                    </div>
                  </span>
                  <input
                    class="form-control"
                    type="email"
                    v-model="formData.email"
                    placeholder="Email Address"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    @focus="usernameFocus(true)"
                    @blur="usernameFocus(false)"
                    :class="
                      v$.formData.email.$dirty && v$.formData.email.$invalid
                        ? `is-invalid`
                        : v$.formData.email.$dirty
                        ? `is-valid`
                        : ``
                    "
                  />
                  <div
                    v-for="error of v$.formData.email.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    Oops! The email address is invalid.
                  </div>
                </div>
                <div class="mt-3 text-center">
                  <div v-if="errorMesage" class="alert alert-danger" role="alert">
                    <p>
                      {{ errorMesage }}
                    </p>
                  </div>
                  <div v-if="successMesage" class="alert alert-success" role="alert">
                    <p>
                      {{ errorMesage }}
                    </p>
                  </div>

                  <div
                    v-if="stk_sent && loading"
                    class="alert alert-success"
                    role="alert"
                  >
                    <p>
                      We have successfully send a payment request to your phone. Please
                      check your phone now.
                    </p>
                    <div class="progress d-flex mx-auto" style="width: 60%">
                      <div
                        class="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style="width: 100%"
                      ></div>
                    </div>
                    <p>
                      This page will refresh automatically after receiving the payment
                    </p>
                  </div>
                  <div v-else class="alert alert-info" role="alert">
                    <p>Bulla Team will get back to you within in 2 working days.</p>
                  </div>
                </div>
                <div class="mb-3 d-flex">
                  <button
                    v-if="can_retry"
                    class="btn btn-success w-20 mt-3 ms-auto"
                    @click="subscribe"
                  >
                    {{ retrying ? "Try again" : "Submit" }}
                    <i class="fa-regular fa-right-to-bracket"></i>
                  </button>
                </div>
                <div class="row g-2 mt-2">
                  <span class="font-sans-serif fs--2 d-flex flex-center"
                    >POS | <a class="mb-0 text-500">Version {{ version }}</a></span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- ===============================================-->
    <!--    End of Main Content-->
    <!-- ===============================================-->

    <div
      class="modal fade"
      id="subscription-modal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="max-width: 800px"
      >
        <div class="modal-content position-relative">
          <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
              <h4 class="mb-1" id="modalExampleDemoLabel">Subscription Plan</h4>
            </div>
            <div class="row p-4 pb-0">
              <!-- Satrter  -->
              <div class="col-lg-4">
                <div class="card h-100">
                  <div class="card-body px-4 py-3 my-2">
                    <h4 class="text-800 text-center">Starter</h4>
                    <div class="alert alert-success mt-3">
                      <h5 class="mb-0 text-800">
                        <small>KES</small> 3,000<span
                          class="fs-0 fw-medium font-sans-serif text-600"
                          >/<small>Month</small></span
                        >
                      </h5>
                    </div>
                    <ul class="fa-ul ms-2 ps-card mb-2">
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-check"></i></span
                          >5 Users
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-check"></i></span
                          >5 Locations
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-check"></i></span
                          >1000 Products
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-infinity"></i></span
                          >Orders
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-infinity"></i></span
                          >Customers
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-infinity"></i></span
                          >Updates
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-times"></i></span
                          ><del>Online Store</del>
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-times"></i></span
                          ><del>Invoices</del>
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-times"></i></span
                          ><del>Quotations</del>
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-times"></i></span
                          ><del>Inventory</del>
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-times"></i></span
                          ><del>Kitchen</del>
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-times"></i></span
                          ><del>Rooms</del>
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-times"></i></span
                          ><del>Printers</del>
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-times"></i></span
                          ><del>Shifts</del>
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-times"></i></span
                          ><del>Terminals</del>
                        </h6>
                      </li>
                    </ul>
                    <button
                      class="btn btn-success w-100"
                      data-bs-dismiss="modal"
                      @click="selectPlan({ id: 1, name: 'starter', price: 3000 })"
                    >
                      <span class="fas fa-crown me-2"></span>Select
                    </button>
                  </div>
                </div>
              </div>
              <!-- Basic  -->
              <div class="col-lg-4">
                <div class="card h-100">
                  <div class="card-body px-4 py-3 my-2">
                    <h4 class="text-800 text-center">Basic</h4>
                    <div class="alert alert-success mt-3">
                      <h5 class="mb-0 text-800">
                        <small>KES</small> 6,000<span
                          class="fs-0 fw-medium font-sans-serif text-600"
                          >/<small>Month</small></span
                        >
                      </h5>
                    </div>
                    <ul class="fa-ul ms-2 ps-card mb-2">
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-check"></i></span
                          >10 Users
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-check"></i></span
                          >10 Locations
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-check"></i></span
                          >3000 Products
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-infinity"></i></span
                          >Orders
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-infinity"></i></span
                          >Customers
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-infinity"></i></span
                          >Updates
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-check"></i></span
                          >Online Store
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-check"></i></span
                          >Invoices
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-check"></i></span
                          >Quotations
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-check"></i></span
                          >Inventory
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-check"></i></span
                          >Kitchen
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-times"></i></span
                          ><del>Rooms</del>
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-times"></i></span
                          ><del>Printers</del>
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-times"></i></span
                          ><del>Shifts</del>
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-times"></i></span
                          ><del>Terminals</del>
                        </h6>
                      </li>
                    </ul>
                    <button
                      class="btn btn-success w-100"
                      data-bs-dismiss="modal"
                      @click="selectPlan({ id: 1, name: 'basic', price: 6000 })"
                    >
                      <span class="fas fa-crown me-2"></span>Select
                    </button>
                  </div>
                </div>
              </div>
              <!-- Premium -->
              <div class="col-lg-4">
                <div class="card h-100">
                  <div class="card-body px-4 py-3 my-2">
                    <h4 class="text-800 text-center">Premium</h4>
                    <div class="alert alert-success mt-3">
                      <h5 class="mb-0 text-800">
                        <small>KES</small> 10000<span
                          class="fs-0 fw-medium font-sans-serif text-600"
                          >/<small>Month</small></span
                        >
                      </h5>
                    </div>
                    <ul class="fa-ul ms-2 ps-card mb-2">
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-infinity"></i></span
                          >Users
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-infinity"></i></span
                          >Locations
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-infinity"></i></span
                          >Products
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-infinity"></i></span
                          >Orders
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-infinity"></i></span
                          >Customers
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-infinity"></i></span
                          >Updates
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-check"></i></span
                          >Online Store
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-check"></i></span
                          >Invoices
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-check"></i></span
                          >Quotations
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-check"></i></span
                          >Inventory
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-check"></i></span
                          >Kitchen
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-check"></i></span
                          >Rooms
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-check"></i></span
                          >Printers
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-check"></i></span
                          >Shifts
                        </h6>
                      </li>
                      <li class="py-1">
                        <h6 class="text-700">
                          <span class="fa-li text-success"
                            ><i class="fas fa-check"></i></span
                          >Terminals
                        </h6>
                      </li>
                    </ul>
                    <button
                      class="btn btn-success w-100"
                      data-bs-dismiss="modal"
                      @click="selectPlan({ id: 1, name: 'premium', price: 10000 })"
                    >
                      <span class="fas fa-crown me-2"></span>Select
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
              Close
            </button>
            <!-- <button class="btn btn-primary" type="button">Understood </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useVuelidate } from "@vuelidate/core";
import UserService from "../services/user.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import { required, email, minLength } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "LoginView",
  components: {
    LoaderOverlay,
  },
  data() {
    return {
      can_retry: true,
      retrying: false,
      errorMesage: false,
      successMesage: false,
      stk_sent: false,
      transaction_complete: false,
      username_focused: false,
      password_focused: false,
      phone_focused: false,
      name_focused: false,
      selectedPlan: {
        id: 1,
        price: 3000,
        name: "starter",
      },
      formData: {
        email: "",
        phone: "",
        name: "",
        password: "",
        remember_me: false,
      },
      users: [],
      version: process.env.VUE_APP_VERSION,
    };
  },
  validations() {
    return {
      formData: {
        password: { required },
        name: { required },
        phone: { required },
        password: { required },
        email: { required, email },
      },
    };
  },
  created() {},
  mounted() {
    // Theme setup custome to bellalisa
    localStorage.setItem("navbarStyle", "card");
    localStorage.setItem("isFluid", true);
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    selectPlan(plan) {
      console.log(plan);
      this.selectedPlan = plan;
    },
    async validateEmail() {
      return await UserService.validateEmail(this.formData.email).then(
        (response) => {
          if (response.data.code == 200) {
            return true;
          } else {
            return false;
          }
        },
        (error) => {
          console.log(error);
          return false;
        }
      );
    },
    async checkStk() {
      this.stk_sent = true;
      this.$store.commit("SET_LOADING", true);
      var params2 = new FormData();
      params2.append("CheckoutRequestID", this.CheckoutRequestID);
      params2.append("BillRefNumber", this.order_reference);
      params2.append("TransAmount", this.subtotal);
      for (let i = 0; i < 5; i++) {
        if (this.transaction_complete) {
          this.stk_sent = false;
          this.$store.commit("SET_LOADING", false);
          this.can_retry = true;
          break;
        } else {
          await axios
            .post("https://samdoh.bulla.co.ke/backend/stk-query.php", params2)
            .then((response) => {
              if (response.data.ResponseCode == 0 && response.data.ResultCode == 0) {
                toast.success(response.data.ResultDesc);
                console.log("Payment complete..");
                this.transaction_complete = true;
                this.subscribe();
              } else if (
                typeof response.data.errorCode != undefined &&
                response.data.errorMessage == "The transaction is being processed"
              ) {
                toast.info(response.data.errorMessage);
              }
              // Insufficient bal
              else if (
                response.data.ResponseCode == 0 &&
                response.data.ResultDesc ==
                  "The balance is insufficient for the transaction."
              ) {
                toast.error(response.data.ResultDesc);
                this.errorMesage = response.data.ResultDesc;
                this.$store.commit("SET_LOADING", false);
              }
              // DS not reached
              else if (
                response.data.ResponseCode == 0 &&
                response.data.ResultCode == 1037
              ) {
                toast.error(response.data.ResultDesc);
                this.transaction_complete = true;
                this.errorMesage = response.data.ResultDesc;
                this.$store.commit("SET_LOADING", false);
              }
              // DS cancelled
              else if (
                response.data.ResponseCode == 0 &&
                response.data.ResultCode == 1032
              ) {
                toast.error(response.data.ResultDesc);
                this.transaction_complete = true;
                this.errorMesage = response.data.ResultDesc;
                this.$store.commit("SET_LOADING", false);
              } else if (
                response.data.ResponseCode == 0 &&
                response.data.ResultCode == 2001
              ) {
                this.transaction_complete = true;
                this.$store.commit("SET_LOADING", false);
              } else {
                toast.info(response.data.ResultDesc);
              }
            });
          await this.sleep(10000);
        }
      }
    },
    subscribe() {
      this.formData.price = this.selectedPlan.price;
      this.formData.plan = this.selectedPlan.name;
      this.$store.commit("SET_LOADING", true);
      UserService.subscribe(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.status == 200) {
            toast.success("User registered, login to access your account!", {
              autoClose: 2000,
            });
            localStorage.setItem("user", JSON.stringify(response.data.data.user));
            localStorage.setItem("token", response.data.data.access_token);
            this.$router.push("/dashboard");
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },

    async pushSTK(params) {
      // format phone
      this.$store.commit("SET_LOADING", true);
      await axios
        .post("https://samdoh.bulla.co.ke/backend/stk-push.php", params)
        .then((response) => {
          console.log(response.data);
          if (response.data.errorMessage || response.data.errorCode) {
            toast.error(response.data.errorMessage);
            this.stk_sent = false;
            this.can_retry = true;
            this.$store.commit("SET_LOADING", false);
          } else if (
            response.data.ResultDesc == "The balance is insufficient for the transaction."
          ) {
            toast.error(response.data.ResultDesc);
            this.stk_sent = false;
            this.can_retry = true;
            this.$store.commit("SET_LOADING", false);
          } else if (response.data.ResultDesc == "Rule limited.") {
            toast.error(response.data.ResultDesc);
            this.stk_sent = false;
            this.can_retry = true;
            this.$store.commit("SET_LOADING", false);
          } else {
            this.CheckoutRequestID = response.data.CheckoutRequestID;
            toast.success("PIN prompt sent to your phone!");
            this.can_retry = true;
            this.checkStk();
          }
        });
    },

    usernameFocus(val) {
      this.username_focused = val;
    },
    passwordFocus(val) {
      this.password_focused = val;
    },
    phoneFocus(val) {
      this.phone_focused = val;
    },
    nameFocus(val) {
      this.name_focused = val;
    },
    setUser(email) {
      console.log(email);
      this.username_key = email;
      this.formData.email = email;
    },
    goToRegister() {
      this.$router.push("/register");
    },
    goToLogin() {
      this.$router.push("/login");
    },
    gotToForgotPassword() {
      this.$router.push("/forgot-password");
    },
    async handleRegister() {
      this.v$.formData.$touch();
      if (this.v$.formData.$invalid) {
        return;
      } else {
        this.$store.commit("SET_LOADING", true);
        this.retrying = true;
        this.can_retry = false;
        this.errorMesage = false;
        this.successMesage = false;
        this.transaction_complete = false;
        let exist = await this.validateEmail();
        if (exist) {
          toast.info("Email aaddress already taken!");
          this.$store.commit("SET_LOADING", false);
          this.can_retry = true;
          return;
        }
        let formData = new FormData();
        formData.append("amount", this.selectedPlan.price);
        formData.append(
          "phone_number",
          "254" + parseInt(this.formData.phone.replace(/\+/g, ""))
        );
        formData.append(
          "account_reference",
          this.formData.email + "," + this.selectedPlan.name
        );
        this.pushSTK(formData);
      }
    },
  },
};
</script>
